<template>
  <div
    class="d-flex justify-content-md-between align-items-start align-items-md-center justify-content-center flex-column flex-md-row"
  >
    <!-- For  perpage selection dropdown -->
    <div
      class="d-flex justify-content-between align-items-center"
      style="width: 10rem"
      v-if="total > 10"
    >
      <small class="m-0 text-dark">Rows Per Page</small>
      <select
        class="form-control custom-perpage"
        v-model="perpage_limit"
        @change="customPerPageChange($event.target.value)"
      >
        <option
          v-for="(i, index) in perpage_list"
          :key="index"
          v-text="i"
          :value="i == 'All' ? -1 : i"
          :selected="perpage_limit == i ? true : false"
        ></option>
      </select>
    </div>
    <small class="m-0 font-weight-bold">Total Records : {{ total }}</small>
    <!-- For arrows and pagiantion page selection -->
    <div
      class="d-flex justify-content-start flex-wrap mt-md-0 mt-3"
      v-if="pages"
    >
      <!-- left arrow -->
      <div
        class="arrow-button"
        :style="{ filter: slice_start == 0 ? 'saturate(0)' : '' }"
        @click="nextPrev('<')"
      >
        <img src="../views/img/left-arrow.png" alt="" />
      </div>
      <!-- Pagination tab's containing page numbers -->
      <div
        class="paginate-button"
        :class="current_page == i ? 'text-dark disabled' : ''"
        v-for="i in pages"
        :key="i"
        v-text="i"
        @click="customPageChange(i)"
      ></div>
      <!-- right arrow -->
      <div
        class="arrow-button"
        :style="{ filter: slice_end >= total_pages ? 'saturate(0)' : '' }"
        @click="nextPrev('>')"
      >
        <img src="../views/img/right-arrow.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/helpers/event-bus";
export default {
  data() {
    return {
      perpage_list: [100, 200, 300, 400, 500, "All"],
      perpage_limit: 100,

      slice_start: 0,
      slice_end: this.pageTabs,

      page_tabs: this.pageTabs,
      total_pages: null,
      pages_array: [],

      current_page: 1,
    };
  },
  props: {
    total: {
      type: Number,
    },
    pageChanged: {
      type: Function,
    },
    perPageChanged: {
      type: Function,
    },
    perPageList: {
      type: Number,
    },
    pageTabs: {
      type: Number,
    },
    serverParams: {
      type: Object,
      default: null,
    },
  },
  computed: {
    pages() {
      // To check if the data does not require pagination Example if length of data is 10 and rows per page is 20 so no need for pagination
      let no_more_pages = Math.trunc(this.total / this.perpage_limit)
        ? false
        : true;

      //To get total number fo pages based upon the rows per page selection
      this.total_pages = Math.ceil(this.total / this.perpage_limit);

      // To get array of total pages based upon the rows per page selection
      this.pages_array = Array.from(
        { length: this.total_pages },
        (x, i) => i + 1
      );
      // console.log(this.pages_array);

      // To slice the array based on the page tabs to show
      let result_arr = this.pages_array.slice(this.slice_start, this.slice_end);
      // console.log(result_arr);

      if (result_arr.length > 0 && no_more_pages == false) {
        result_arr.length == 1 ? (result_arr = []) : "";
        return this.pages_array.slice(this.slice_start, this.slice_end);
      }
    },
  },
  methods: {
    customPageChange(page) {
      this.current_page = page;
      this.pageChanged({ currentPage: page });
    },
    customPerPageChange(event) {
      this.perPageChanged({ currentPerPage: Number(event) });
      this.current_page = 1;
      this.slice_start = 0;
      this.slice_end = this.page_tabs;
    },
    nextPrev(action) {
      if (action == "<" && this.slice_start > 0) {
        this.slice_start -= this.page_tabs;
        this.slice_end -= this.page_tabs;
      }
      if (action == ">" && this.slice_end < this.total_pages) {
        this.slice_start += this.page_tabs;
        this.slice_end += this.page_tabs;
      }
    },
  },
  watch: {
    serverParams: {
      handler(nv, ov) {
        this.current_page = nv.page;
        this.perpage_limit = nv.perpage;
      },
      immediate: true,
    },
  },
  mounted() {
    this.current_page = this.serverParams.page;
    this.perpage_limit = this.serverParams.perpage;
    // console.log("Mounted");
    EventBus.$on("reset-page-perpage", (params) => {
      this.serverParams.page = params.page;
      this.serverParams.perpage = params.perpage;
    });
  },
};
</script>

<style scoped>
.paginate-button {
  height: 25px;
  min-width: 25px;
  width: max-content;
  background: #edf1ff;
  color: #4e73df;
  margin: 5px;
  /* padding-top:4px; */
  text-align: center;
  cursor: pointer;
  /* font-weight: bold; */
  border-radius: 4px;
}
.arrow-button img {
  height: 20px;
  width: 20px;
  color: #4e73df;
  margin: 7px 5px;
  /* padding-top:4px; */
  text-align: center;
  cursor: pointer;
  /* font-weight: bold; */
  border-radius: 4px;
}

.custom-perpage {
  height: 32px;
  width: 60px;
  font-size: 14px;
  padding: 4px;
}
</style>
